import { useState } from 'react';
import * as api from '../../../api';
import {
  IPreHeaderRoleMessage,
  IPreHeaderRoleMessageWhiteList,
  WHITE_LIST_TYPES,
} from '../models/preheader.models';
import { feedback } from '../../../utils';

const usePreHeaderCheckWhiteList = ({
  message,
}: {
  message: IPreHeaderRoleMessage;
}) => {
  const [whiteListItems, setWhiteListItems] = useState<
    IPreHeaderRoleMessageWhiteList[]
  >(message.preHeaderWhiteList);

  const [loading, setLoading] = useState(false);

  const createWhiteList = (e: any, item: String) => {
    setLoading(true);
    if (e.target.checked) {
      api
        .postDataCall({
          dataPath: '/pre-header-white-list',
          data: {
            preHeaderMessageId: message.id,
            type: WHITE_LIST_TYPES.URL,
            matchType: 'exact',
            value: item,
          },
        })
        .then((response) => {
          setWhiteListItems([...whiteListItems, response.data]);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          feedback(error.message);
        });
    } else {
      const id = whiteListItems.find(
        (whiteListItem) => whiteListItem.value === item,
      )?.id;

      api
        .deleteCall({
          dataPath: `/pre-header-white-list/${id}`,
        })
        .then(() => {
          setWhiteListItems(whiteListItems.filter((item) => item.id !== id));
        })
        .catch((error) => {
          feedback(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { whiteListItems, loading, createWhiteList };
};

export default usePreHeaderCheckWhiteList;
