import React from 'react';
import { pagesConfigurationItems } from '../../../constants';
import { Checkbox } from 'antd';
import usePreHeaderCheckWhiteList from '../../../hooks/usePreHeaderCheckWhiteList';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
} from '../../../models/preheader.models';

const PreHeaderCheckWhiteList = ({
  role,
  message,
}: {
  role: IPreHeaderRole;
  message: IPreHeaderRoleMessage;
}) => {
  const { whiteListItems, loading, createWhiteList } =
    usePreHeaderCheckWhiteList({ message });

  return (
    <div
      style={{
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
      }}>
      {Object.keys(pagesConfigurationItems).map((item) => (
        <div>
          <Checkbox
            disabled={loading}
            checked={whiteListItems.some(
              (whiteListItem) =>
                whiteListItem.value ===
                pagesConfigurationItems[
                  item as keyof typeof pagesConfigurationItems
                ],
            )}
            onChange={(e) =>
              createWhiteList(
                e,
                pagesConfigurationItems[
                  item as keyof typeof pagesConfigurationItems
                ],
              )
            }>
            {item}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default PreHeaderCheckWhiteList;
