import React from 'react';
import Title from 'antd/lib/typography/Title';
import {
  IPreHeaderRole,
  PreHeaderRoleListKey,
  WHITE_LIST_TYPES,
} from '../../models/preheader.models';

import {
  PreHeaderCheckWhiteList,
  PreHeaderWhiteList,
} from './PreHeaderWhiteList';

const PreHeaderPagesConfiguration = ({
  role,
  message,
  handleChange,
}: {
  role: IPreHeaderRole;
  message: any;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
}) => {
  return (
    <div>
      <Title level={3}>*Pages Configuration </Title>
      <PreHeaderCheckWhiteList role={role} message={message} />
      <br />
      <br />
      <Title level={3}>URL </Title>
      <p>Introduce on url</p>
      <PreHeaderWhiteList
        type={WHITE_LIST_TYPES.URL}
        role={role}
        message={message}
      />
      <br />
      <Title level={3}>UTM </Title>
      <p>Introduce on utm</p>
      <PreHeaderWhiteList
        type={WHITE_LIST_TYPES.UTM}
        role={role}
        message={message}
      />
    </div>
  );
};

export default PreHeaderPagesConfiguration;
