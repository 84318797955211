export const hiddenButonsWYSWYGComponent = [
  'source',
  'strikethrough',
  'italic',
  'eraser',
  'superscript',
  'subscript',
  'ul',
  'ol',
  'outdent',
  'indent',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  'image',
  'file',
  'video',
  'table',
  'align',
  'undo',
  'redo',
  'selectall',
  'cut',
  'copy',
  'paste',
  'copyformat',
  'left',
  'hr',
  'br',
  'symbol',
  'fullsize',
  'print',
  'preview',
  'find',
  'about',
  'dots',
];

export enum pagesConfigurationItems {
  Home = '/',
  PDP = '/p/[name]/[id]',
  'Ecommerce Grids' = '/c/[...category]',
  'Register/Login' = '/login, /register, /checkout-login',
  'LOVE ISDIN grid' = '/love-isdin',
  EDPs = 'love-isdin/experience/[slug]',
  // 'Specific Pages' = 'Specific Pages',
}

export const MESSAGE_MAX_LENGTH = 74;
